// import './hoverIntent.js';
// import './superfish.js';
import './KCT-mobilenav.js';
// import './slick.js';
import '../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.js';
// import AOS from './aos.js';

// animate on scroll plugin. config is in data attributes
// AOS.init({
//   duration: 1200,
// });

// grab an element
// var headroomElement = document.querySelector("header");
// // construct an instance of Headroom, passing the element
// var headroom  = new Headroom(headroomElement, {
//   "offset": 0,
//   "tolerance": 30,
// });
// initialise
// headroom.init();

$("document").ready(function () {
    // if the contact form is in the footer, this scrolls to it
    // and focuses the first field.

    $('a[href*="#contact"]').click(function(e) {
      e.preventDefault();
      $("html").removeClass("openNav");
      $('.open-panel').removeClass('is-active');
      $('html, body').animate({
          scrollTop: $("#contact").offset().top
      }, 700, function() {
          $('[name="Your Name"]').focus();
      });
    });
});

// This is a simple responsive utility: put things in here
// if you want something to happen on window resize.
// function resizedw(){
//   // Haven't resized in 250ms. Add scripts here.
// }
//
// var doit;
// window.onresize = function(){
//   clearTimeout(doit);
//   doit = setTimeout(resizedw, 250);
//
// };
